































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class InsCmsParentCat extends Vue {
  @Prop() private cmsCategory!: object;
  cmsParentCat: object[] = [];
  showCatList: boolean = false;

  // 获取当前id父目录
  getParCatByDevice() {
    if (
      (this as any).cmsCategory.CatPaths &&
      (this as any).cmsCategory.CatPaths.length > 0 &&
      (this as any).cmsCategory.CatPaths[0].CatId
    ) {
      let parentId = (this as any).cmsCategory.CatPaths[0].CatId;
      // console.log(parentId, '父级parentId');
      this.$Api.cms
  .getCategoryByDevice({ CatId: parentId, IsMobile: this.isMobile })
  .then((result) => {
    // 使用 filter 方法去除 ID 为 40131, 40239, 40127 的元素
    this.cmsParentCat = result.Children.filter(item => {
      return item.Id !== 40131 && item.Id !== 40239 && item.Id !== 40127;
    });
    console.log(this.cmsParentCat, "this.cmsParentCat");
  })
  .catch(error => {
    console.error("Error fetching categories:", error);
  });
    }
  }
  handleClick(id) {
    window.location.href = "/cms/catDetail/" + id;
  }

  get isMobile() {
    return this.$store.state.isMobile;
  }

  To(n) {
    return "/cms/catDetail/" + n.Id;
  }
  mounted() {
    this.getParCatByDevice();
  }
  @Watch("cmsCategory", { deep: true })
  oncmsCategoryChange() {
    console.log(this.cmsCategory, "传过来的cmsCategory");
    this.cmsParentCat = [];
    this.getParCatByDevice();
  }
}
